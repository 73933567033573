import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import useLogout from '../../hooks/useLogout';
import { SelectorIcon } from '@heroicons/react/solid';
import useAuth from '../../hooks/useAuth';
import { Link, Route, Routes } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ROLES } from '../../App';

const UserProfileDropdown = ({ mobile }) => {
   const logout = useLogout();
   const { auth } = useAuth();

   const decoded = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

   const roles = decoded?.UserInfo?.roles || [];

   // const name =
   //    !auth?.firstname && !auth?.lastname
   //       ? auth?.user
   //       : `${auth?.firstname} ${auth?.lastname}`;

   const menuClassName = {
      className: mobile
         ? 'relative ml-3'
         : 'px-3 relative inline-block text-left',
   };

   const menuItems1 = [
      { url: '/religious-education', title: 'Religious Education' },
      { url: '/baptisms', title: 'Baptisms' },
      ...(!roles.includes(ROLES.SchoolUser)
         ? [
              { url: '/website', title: 'Website' },
              { url: '/weddings', title: 'Weddings' },
              { url: '/funerals', title: 'Funerals' },
           ]
         : []),
   ];
   const menuItems2 = [
      { url: '/settings/manage-profile', title: 'Your Profile' },
      { url: '/billing', title: 'Billing' },
      { url: '/settings', title: 'Settings' },
   ];

   return (
      <Menu as='div' {...menuClassName}>
         <div>
            {mobile ? (
               <Menu.Button
                  type='button'
                  className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                  id='user-menu-button'
                  aria-expanded='false'
                  aria-haspopup='true'
               >
                  <span className='sr-only'>Open user menu</span>
                  {auth?.photo?.src ? (
                     <img
                        className='h-8 w-8 rounded-full'
                        src={auth.photo.src}
                        loading='lazy'
                        alt=''
                     />
                  ) : (
                     <div className='h-8 w-8 rounded-full flex items-center justify-center bg-gray-500'>
                        <span className='text-gray-50'>
                           {auth?.firstname && auth.firstname[0]}
                        </span>
                     </div>
                  )}
               </Menu.Button>
            ) : (
               <Menu.Button
                  type='button'
                  className='group w-full bg-gray-600 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500'
                  id='options-menu-button'
                  aria-expanded='false'
                  aria-haspopup='true'
               >
                  <span className='flex w-full justify-between items-center'>
                     <span className='flex min-w-0 items-center justify-between space-x-3'>
                        {auth?.photo?.src ? (
                           <img
                              className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0'
                              src={auth.photo.src}
                              loading='lazy'
                              alt=''
                           />
                        ) : (
                           <div className='h-10 w-10 rounded-full flex items-center justify-center bg-gray-300'>
                              <span className='text-gray-700'>
                                 {auth?.firstname && auth.firstname[0]}
                              </span>
                           </div>
                        )}
                        <span className='flex-1 flex flex-col min-w-0'>
                           <span className='text-gray-300 text-sm font-medium truncate'>
                              <Routes>
                                 <Route
                                    path='/dashboard/*'
                                    element={<>Dashboard</>}
                                 />
                                 <Route
                                    path='/weddings/*'
                                    element={<>Weddings</>}
                                 />
                                 <Route
                                    path='/baptisms/*'
                                    element={<>Baptisms</>}
                                 />

                                 {/* <Route path='/donor-management/*' element={<Future />} /> */}
                                 {/* <Route path='/sacramental-records/*' element={<Future />} /> */}
                                 <Route
                                    path='/website/*'
                                    element={<>Website</>}
                                 />
                                 <Route
                                    path='/religious-education/*'
                                    element={<>Religious Education</>}
                                 />
                                 <Route
                                    path='/funerals/*'
                                    element={<>Funerals</>}
                                 />
                                 {/* <Route path='/profile/*' element={<Future />} /> */}
                                 <Route
                                    path='/billing/*'
                                    element={<>Billing</>}
                                 />
                                 <Route
                                    path='/settings/*'
                                    element={<>Settings</>}
                                 />
                              </Routes>
                           </span>
                           <span className='text-gray-400 text-sm truncate'>
                              {auth?.user}
                           </span>
                        </span>
                     </span>
                     <SelectorIcon className='flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                  </span>
               </Menu.Button>
            )}
         </div>
         <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
         >
            <Menu.Items
               className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none z-30'
               role='menu'
               aria-orientation='vertical'
               aria-labelledby='user-menu-button'
               tabIndex='-1'
            >
               {menuItems1.length > 0 && (
                  <div className='py-1' role='none'>
                     {menuItems1
                        .filter(item => !item?.hidden)
                        .map((item, index) => (
                           <Menu.Item key={item.title + (index + 1).toString()}>
                              <Link
                                 to={item.url}
                                 className='text-gray-700 block px-4 py-2 text-sm'
                                 role='menuitem'
                                 tabIndex='-1'
                                 id={`user-menu-item-${index}`}
                              >
                                 {item.title}
                              </Link>
                           </Menu.Item>
                        ))}
                  </div>
               )}
               {menuItems2.length > 0 && (
                  <div className='py-1' role='none'>
                     {menuItems2.map((item, index) => (
                        <Menu.Item key={item.title + (index + 1).toString()}>
                           <Link
                              to={item.url}
                              className='text-gray-700 block px-4 py-2 text-sm'
                              role='menuitem'
                              tabIndex='-1'
                              id={`user-menu-item-${menuItems1.length + index}`}
                           >
                              {item.title}
                           </Link>
                        </Menu.Item>
                     ))}
                  </div>
               )}
               <div className='py-1' role='none'>
                  <Menu.Item>
                     <button
                        className='text-gray-700 block px-4 py-2 text-sm'
                        role='menuitem'
                        tabIndex='-1'
                        id='user-menu-item-0'
                        onClick={logout}
                     >
                        Logout
                     </button>
                  </Menu.Item>
               </div>
            </Menu.Items>
         </Transition>
      </Menu>
   );
};

export default UserProfileDropdown;
