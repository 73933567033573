import { useState } from 'react';

const useInput = <T = string>(initValue: T, { lowerCased = false } = {}) => {
   const [value, setValue] = useState<T>(initValue);

   const reset = () => {
      setValue(initValue);
   };

   const attrObj = {
      value,
      onChange: (
         ev:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
      ) => {
         setValue(
            lowerCased && typeof ev.target.value === 'string'
               ? (ev.target.value.toLowerCase() as T)
               : (ev.target.value as T)
         );
      },
   };

   return [value, attrObj, reset, setValue] as const;
};

export default useInput;
