import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

type TAcceptedTypes =
   | 'pdf'
   | 'png'
   | 'jpeg'
   | 'jpg'
   | 'image'
   | 'xlsx'
   | 'xls'
   | 'any';

type TOptions = {
   type?: TAcceptedTypes[];
   maxSizeInMB?: number;
};

export default function useFileInput(
   initialFile: File | {} | null,
   { type = ['pdf'], maxSizeInMB = 20 }: TOptions = {}
) {
   const [file, setFile] = useState(initialFile);

   const onDrop = useCallback(
      (acceptedFiles: File[]) => {
         const file = acceptedFiles[0];
         if (!file) return toast.info('Unsupported file format!');
         if (file.size > Number(maxSizeInMB) * 1024 * 1024)
            return toast.error(
               `File is too large! Up to ${maxSizeInMB}MB is accepted.`
            );
         setFile(file);
      },
      [setFile, maxSizeInMB]
   );

   const fileOptions = useDropzone({
      onDrop,
      multiple: false,
      ...(!type.includes('any') && {
         accept: {
            ...(type.includes('pdf') && { 'application/pdf': ['.pdf'] }),
            ...(type.includes('png') && { 'image/png': ['.png'] }),
            ...((type.includes('jpeg') || type.includes('jpg')) && {
               'image/jpeg': ['.jpg', '.jpeg'],
            }),
            ...(type.includes('xlsx') && {
               'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
            }),
            ...(type.includes('xls') && {
               'application/vnd.ms-excel': ['.xls'],
            }),
            ...(type.includes('image') && { 'image/*': [] }),
         },
      }),
   });

   return [file, fileOptions, setFile] as const;
}
