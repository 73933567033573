import {
   HomeIcon,
   AcademicCapIcon,
   LibraryIcon,
   UserGroupIcon,
   UserIcon,
   FilmIcon,
} from '@heroicons/react/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

const UsersIconOutline = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'
      />
   </svg>
);
export const NewDocumentIcon = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
      />
   </svg>
);
const SettingsIconOutline = () => (
   <svg
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='2'
      stroke='currentColor'
      aria-hidden='true'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z'
      />
   </svg>
);

const EducationSidebarItems = ({ setSidebarOpen, mobile }) => {
   const primarySidebarItems = [
      {
         name: 'Home',
         href: '/religious-education',
         svg: HomeIcon,
         end: true,
      },
      {
         name: 'Training',
         href: '/religious-education/training',
         svg: FilmIcon,
         end: true,
      },
      {
         title: 'ENROLLMENT',
         type: 'header',
         items: [
            {
               name: 'Students',
               href: '/religious-education/students',
               svg: AcademicCapIcon,
            },
            {
               name: 'Parents',
               href: '/religious-education/parents',
               svg: UsersIconOutline,
            },
         ],
      },
      {
         title: 'CLASSES',
         type: 'header',
         items: [
            {
               name: 'Classes',
               href: '/religious-education/classes',
               svg: LibraryIcon,
            },
            {
               name: 'Teachers',
               href: '/religious-education/teachers',
               svg: UserGroupIcon,
            },
            {
               name: 'Volunteers',
               href: '/religious-education/volunteers',
               svg: UsersIconOutline,
            },
         ],
      },
      {
         title: 'SCHOOL YEAR',
         type: 'header',
         items: [
            {
               name: 'Registrations',
               href: '/religious-education/registrations',
               svg: NewDocumentIcon,
            },
            {
               name: 'Settings',
               href: '/religious-education/years',
               svg: SettingsIconOutline,
            },
            // {
            //    name: 'Import DOCS',
            //    href: '/religious-education/import-docs',
            //    svg: UploadIcon,
            // },
         ],
      },
      {
         title: 'SACRAMENTS',
         type: 'header',
         items: [
            {
               name: 'Sponsors',
               href: '/religious-education/sponsors',
               svg: UserIcon,
            },
         ],
      },
      {
         title: 'REPORTS',
         type: 'header',
         items: [
            {
               name: 'Attendance',
               href: '/religious-education/attendance',
               svg: NewDocumentIcon,
            },
            {
               name: 'Registration Payments',
               href: '/religious-education/registration-payments',
               svg: NewDocumentIcon,
            },
            {
               name: 'Baptism Report',
               href: '/religious-education/baptism-report',
               svg: NewDocumentIcon,
            },
            // {
            //    name: 'Pickup Report',
            //    href: '/religious-education/pickup-report',
            //    svg: NewDocumentIcon,
            // },
            {
               name: 'OCIA Report',
               href: '/religious-education/ocia-report',
               svg: NewDocumentIcon,
            },
            {
               name: 'Notes Report',
               href: '/religious-education/notes-report',
               svg: NewDocumentIcon,
            },
            {
               name: 'Saint Name Report',
               href: '/religious-education/saint-name-report',
               svg: NewDocumentIcon,
            },
            {
               name: 'Missing Docs Report',
               href: '/religious-education/missing-docs',
               svg: NewDocumentIcon,
            },
            {
               name: 'Inactive Students Report',
               href: '/religious-education/inactive-students',
               svg: NewDocumentIcon,
            },
            {
               name: 'Missing BC Report',
               href: '/religious-education/bc-report',
               svg: NewDocumentIcon,
            },
            {
               name: 'School Sacraments',
               href: '/religious-education/school-sacraments',
               svg: NewDocumentIcon,
            },
            {
               name: 'Out of Sequence',
               href: '/religious-education/out-of-sequence',
               svg: NewDocumentIcon,
            },
            {
               name: 'Easter Vigil Report',
               href: '/religious-education/easter-vigil-report',
               svg: NewDocumentIcon,
            },
         ],
      },
   ];

   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default EducationSidebarItems;
