export const BASE_URL = '';
export const AUTH_API = '/auth';
export const WEDDING_API = '/api/weddings';
export const CHURCHES_API = '/api/churches';
export const DIOCESE_API = '/api/dioceses';
export const PRIESTS_API = '/api/priests';
export const STUDENTS_API = '/api/education/students';
export const PARENTS_API = '/api/education/parents';
export const SCHOOL_YEARS_API = '/api/education/school_years';
export const SCHOOL_CLASSES_API = '/api/education/classes';
export const REGISTRATIONS_API = '/api/education/registrations';
export const TEACHERS_API = '/api/education/teachers';
export const VOLUNTEERS_API = '/api/education/volunteers';
export const SPONSORS_API = '/api/education/sponsors';
export const FUNERALS_API = '/api/funerals';
export const STAFFS_API = '/api/staffs';
export const PAYMENT_API = '/payment';
export const EXTERNAL_PARENT_REGISTRATION_API = '/api/ext-parent';
export const WEBSITE_API = '/api/website';
export const DOWNLOAD_API = '/api/export';
export const EMAIL_API = '/api/emails';
export const VIDEO_TRAINING_API = '/api/video-training';
export const BAPTISM_DATES_API = '/api/baptism-dates';
export const BAPTISMS_API = '/api/baptisms';
export const RECAPTCHA_SITE_KEY = '6LceL1skAAAAACzdC6yCWwVjo6SvVNQu5eYn_Y6_';
export const STRIPE_PUBLISHABLE_KEY =
   'pk_live_51JuOlCI9BFXwFWGIuw6zL917IcfNNHZ2GVNhWCpixwWWC1caFsrMl5pcvMilcNWDE2VUADsgHpzkGuu8xLnAz9zc00BXtl5cIS';
